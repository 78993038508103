import  Sidebar  from "../../components/Sidebar";

const Dashboard: React.FC = () => {
  
  return (
    <>
      <Sidebar />
    </>
    
  );
};

export default Dashboard;
